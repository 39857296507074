import React from "react"
import '../styles/index.scss'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import pageStyles from './page.module.scss'

const options = {
    renderNode: {
        'embedded-asset-block': (node) => {
            return (
                <div className={pageStyles.pageImageContainer}>
                    <img 
                        src={node.data.target.fields.file['en-US'].url} 
                        alt={node.data.target.fields.title}
                        className={pageStyles.pageImage} />
                    <h5 className={pageStyles.pageImageCaption}>{node.data.target.fields.description['en-US']}</h5>
                </div>
            )
        }
    },
}


const Page = (props) => {
    const { title, content } = props.data.contentfulPage;
    return (
        <Layout>
            <h3>{title}</h3>
            {content && content.json && documentToReactComponents(content.json, options)}
        </Layout>
    );
}

export default Page;

export const query = graphql`
    query ($slug: String!) {
        contentfulPage(slug: { eq: $slug } ) {
            title
            content {
                json
            }
        }
    }
`
